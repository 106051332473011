<template>
  <div class="Home21">
    Home21
    <a-input v-model="text"></a-input>
   
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
const text = ref("");
onMounted(() => {

});




</script>

<style lang="less" scoped></style>
